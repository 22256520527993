import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import playIcon from '@/icons/ui-play.svg'
import pauseIcon from '@/icons/ui-pause.svg'
import BetterIcon from '@/components/utils/BetterIcon'

const PlayPause = ({ icon, playing }) => (
	<span className="block border-3 rounded-circle border-orange mr-0-75 flex items-center justify-center w-40 h-40">
		<BetterIcon icon={icon} className="mx-auto w-full h-10" />
		<span className="visuallyhidden">{!playing ? 'play' : 'pause'}</span>
	</span>
)

class EpisodesListing extends Component {
	/**
	 * @method onClick
	 * update the current track, and togglePlay if it makes sense
	 * the function is called within an inline click handle, which
	 * handles the event object (i.e. preventDefault)
	 *
	 * @param {number} index
	 *
	 */
	onClick = track => {
		// reset the current audio state
		const { setTrack } = this.props

		setTrack(track)
	}

	render() {
		const { episodes, current, playing, track, togglePlay } = this.props

		return (
			<div className="pb-3 border-t-2 border-tundora">
				<div className="text-xs-fluid">
					{episodes.map(item => (
						<div
							key={item.id}
							className="border-b-2 border-tundora pt-0-5 pb-1 lg:pt-1 sm:py-0-75"
						>
							<div className="w-full sm:w-auto flex items-start flex-wrap lg:flex-no-wrap">
								<h2 className="pr-0-75 mb-1 w-full lg:order-2 lg:-mt-0-5 lg:mb-0">
									{item.id === current ? (
										<span className="text-lg-fluid font-bold lowercase text-orange focus:text-orange outline-none trans trans-color">
											{item.title}
										</span>
									) : (
										<Link
											to={item.uri}
											className="text-lg-fluid font-bold lowercase hover:text-orange focus:text-orange outline-none trans trans-color"
										>
											{item.title}
										</Link>
									)}
								</h2>

								<div className="mr-0-5 sm:mb-0 lg:order-1">
									<Link to={item.uri} onClick={() => this.onClick(item)}>
										<PlayPause icon={playIcon} playing={playing} />
									</Link>
								</div>

								<div className="meta inline-flex ml-auto items-center justify-end lg:order-3 lg:flex-1 whitespace-no-wrap">
									<span className="sm:text-right text-grey-light">
										episode {item.episodeNumber}
									</span>
									<span className="w-px bg-grey-brd h-20 mx-0-5 block" />
									<span className="text-grey-light text-right">
										{item.postDate}
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({
	player: {
		setAudioBarVisibility,
		setPageType,
		togglePlay,
		reset,
		setCurrentPageId,
		stop,
		setTrack
	}
}) => ({
	setAudioBarVisibility,
	setPageType,
	togglePlay,
	reset,
	setCurrentPageId,
	stop,
	setTrack
})

export default connect(
	mapState,
	mapDispatch
)(EpisodesListing)

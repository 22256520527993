import React, { Fragment } from 'react'
import Hero from '@/components/Hero/Hero'
import EpisodesListing from '@/components/EpisodesListing/EpisodesListing'
import PostWysiwyg from '@/components/PostWysiwyg/PostWysiwyg'

export default ({
	title,
	subtitle,
	image,
	tags,
	episodes,
	id,
	track,
	entry,
	url,
	episode,
	date,
	body,
	banner
}) => (
	<Fragment>
		<div className="mb-2">
			<Hero
				title={title}
				subtitle={subtitle}
				image={banner || image}
				tags={tags}
				entry={entry}
				url={url}
			/>
		</div>
		<div className="wrapper mb-2 lg:mb-4">
			<div className="max-w-800 mx-auto">
				<h1 className="text-xlh-fluid mb-2 font-bold lowercase">{title}</h1>
				{(episode || date) && (
					<p className="mb-0-5 text-grey-light text-md-fluid">
						{episode && (
							<span className="mr-1 pr-1 border-r border-grey-light">
								episode {episode}
							</span>
						)}
						{date && <span>{date}</span>}
					</p>
				)}

				<PostWysiwyg>
					<div dangerouslySetInnerHTML={{ __html: body }} />
				</PostWysiwyg>
				<h2 className="text-white font-bold text-h2-fluid lowercase mb-2">
					Previous episodes
				</h2>
				{episodes && (
					<EpisodesListing episodes={episodes} current={id} track={track} />
				)}
			</div>
		</div>
	</Fragment>
)
